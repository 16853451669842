import ActionAreaCard from "../componentsMaterialUI/ActionAreaCard";
import BasicStack from "../componentsMaterialUI/BasicStack";

export default function QuemSomos() {
  return (
    <div>
      <div id="quemsomos">
        <div id="centerconteud">
          <br></br>
          <br></br>
          <br></br>
          <br></br> <h2>QUEM SOMOS</h2>
          <br></br>
          <ActionAreaCard></ActionAreaCard>
          <br></br>
        </div>
        <BasicStack />
      </div>
    </div>
  );
}
